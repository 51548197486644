import React from 'react'
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { Button } from "@material-ui/core";
import { connect } from 'react-redux';

// import Funnel from '../dashboard/Funnel';
import { getDashboardLogo, getFunnelData, getUserName, getUserRole } from '../../selectors/app';

import DashboardActive from '../../assets/Icons/dashboard-active.png';
import PreScreening from '../../assets/pre-screening.svg';
import PreScreeningActive from '../../assets/pre-screening-active.svg';
import TasksIcon from '../../assets/Icons/tasks.png';
import ContactsIcon from '../../assets/Icons/contacts.png';
import WidgetAlt from '../../assets/Icons/Widget_alt.png';
import Logo from '../../assets/Logo.png';

import "./sidebar.css";

function Sidebar1(props) {
	const { userName, funnelData, refObj, dashboardLogo } = props;

	let location = useLocation();

	return (
		<div style={{ width: "20vw" }}>
			<div className="sidebar" style={{ display: 'flex', flexDirection: 'column' }}>
				<div className="sidebarWrapper" style={{ flex: 1 }} >
					<div className="logo-div">
						<img style={{ height: "auto", width: "11vw" }} src={dashboardLogo} alt="logo" />
					</div>
					<div>
						<div className="user-div">
							<div className="user-details">
								<h4 style={{ left: "0", padding: '0 8px', fontSize: '14px' }}>Welcome,<br /> {userName}</h4>
							</div>
						</div>
						<div className={window.location.pathname === "/workflows" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"}>
							<Link to={{ pathname: "/workflows", state: { sidebar: "workflows" } }} className="link">
								<Button><img src={DashboardActive} alt="" />&nbsp;Recruitment Drives</Button>
							</Link>
						</div>
						<div className={window.location.pathname === "/pre-screening/dashboard" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"}>
							<Link to={{ pathname: "/pre-screening/dashboard", state: { sidebar: "preScreening" } }} className="link">
								<Button><img src={window.location.pathname === "/pre-screening/dashboard" ? PreScreeningActive : PreScreening} alt="" style={{ margin: "0 -2px -2px" }} />&nbsp;Pre-screening Stage</Button>
							</Link>
						</div>
						<div className="sidebarMenu">
							<div
								style={{ textDecoration: "none" }}>
								<Button disabled><img src={TasksIcon} alt="" />&nbsp;Quick Overview</Button>
							</div>
						</div>
						<div className="sidebarMenu">
							<div
								style={{ textDecoration: "none" }}>
								<Button disabled><img src={DashboardActive} alt="" />&nbsp;Manage Invitations</Button>
							</div>
						</div>
						<div className="sidebarMenu">
							<div
								style={{ textDecoration: "none" }}>
								<Button disabled><img src={WidgetAlt} alt="" style={{ marginLeft: "-2px" }} />&nbsp;Review Applicants</Button>
							</div>
						</div>
						<div className={window.location.pathname === "/team" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"}>
							<Link to={{ pathname: "/team", state: { sidebar: "team" } }} style={{ textDecoration: "none" }}>
								<Button className='dashboard-ninth-step'><img src={ContactsIcon} alt="" />&nbsp;Manage Team</Button>
							</Link>
						</div>
					</div>
					{location.state?.sidebar == 'team' ? null :
						<div style={{ padding: "5px", marginTop: "20px", width: "95%", margin: "0 auto", justifyContent: "center", alignText: "center", borderRadius: "10px", border: "2px solid #aaa" }}>
							{/* <div style={{ marginTop: 15, marginBottom: 15 }}>
								<Funnel
									invited={funnelData.total}
									submitted={funnelData.submitted}
									shortlisted={funnelData.shortlisted}
									hired={funnelData.hired}
								/>
							</div> */}
							<div className="chart" style={{marginTop:"20px"}} ref={refObj}/>
							<div style={{ display: "flex", justifyContent: "center", alignText: "center" }}>
								<p style={{ fontWeight: "bold" }}>Recruitment Funnel</p>
							</div>
						</div>
					}
				</div>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '15px 10px', borderTop: '1px solid #EBEFF2' }}>
					<div style={{ fontSize: 11, color: '#334D6E', fontWeight: '400', marginRight: 15 }}>All rights reserved ©</div>
					<div style={{ display: 'flex' }}>
						<img src={Logo} alt='' style={{ height: 14 }} />
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	userName: getUserName(),
	userRole: getUserRole(),
	funnelData: getFunnelData(),
	dashboardLogo: getDashboardLogo()
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar1);
