import React, { useEffect, useState } from 'react';
import Feedback from './Feedback';
import Modal from 'react-responsive-modal';
import { Button } from '@material-ui/core';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useLocation } from 'react-router-dom';
import { useWindowDimensions } from '../../utils/windowUtils';

import axios from "../../axios";

import '../../styles/modal.css';
import '../../styles/chatboxProgressBar.scss';

const ProgressBar = (props) => {
	const { progress, timerState, startT, setCaseState, setStopChat, totalChatTime } = props;

	const location = useLocation();

	const [showFeedback, setShowFeedBack] = useState(false);
	const [modal, setModal] = useState(false);
	const [completedChatModal, setCompletedChatModal] = useState(false);

	const url = '/userconvo';
	const Parentdiv = {
		height: "2vh",
		backgroundColor: 'whitesmoke',
		borderRadius: 40,
		dispaly: "flex",
		margin: "auto 2vw auto 4vw",
		width: "25vw"
	};
	const Childdiv = {
		height: '100%',
		width: `${progress}%`,
		backgroundColor: "#3AB9D6",
		borderRadius: 40,
		textAlign: 'right'
	};
	const progresstext = {
		position: "relative",
		top: "3vh",
		display: "flex",
		width: "15vw",
		padding: "0 10px",
		color: '#131B23',
		fontSize: "1.7vh",
		fontWeight: "600"
	};

	const onOpenModal = () => { setModal(true) };

	const onCloseModal = () => setModal(false);

	const onOpenCompletedChatModal = () => {
		setStopChat(true);
		setCompletedChatModal(true);
	};

	const onCloseCompletedChatModal = () => {
		const postData = {
			user_id: location.state.code,
			comp: location.state.comp,
			mode: 3,
			message1: '',
			message2: ''
		}

		setCompletedChatModal(false);

		axios
			.post(url, postData)
			.then(() => {
				setCaseState(false);
				setShowFeedBack(true);
			})
			.catch((err) => {
				console.log(err)
			});
	};

	const onSubmit = () => {
		if (!showFeedback) {
			setStopChat(true)
			onCloseModal()
			const postData = {
				user_id: location.state.code,
				comp: location.state.comp,
				mode: 3,
				message1: '',
				message2: ''
			}

			axios
				.post(url, postData)
				.then((res) => {
					setCaseState(false);
					setShowFeedBack(true);
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}

	const renderTime = ({ remainingTime }) => {
		let timerminutes = Math.floor(remainingTime / 60);
		let timerseconds = remainingTime % 60;

		if (remainingTime === 0) {
			timerminutes = 0;
			timerseconds = 0;
		}

		return (
			<div className="timer bot-fifth-step" style={{ position: "absolute", left: "-7vw", fontSize: "1.5vw", display: "flex", color: timerminutes >= 5 ? "#868686" : "red", fontWeight: "600", margin: "auto" }}>
				<span style={{ marginRight: "0.4vh" }}>Time</span><span style={{ marginRight: "1vh" }}> Left</span>
				<div className="minutesvalue">{timerminutes < 10 ? `0${timerminutes}` : timerminutes}:</div>
				<div className="secondsvalue">{timerseconds < 10 ? `0${timerseconds}` : timerseconds}</div>
			</div>
		);
	};

	useEffect(() => {
		if (progress >= 100) {
			onSubmit();
		}
	}, [progress]);

	return (
		<div id="chatboxProgressBar" style={{ height: "12vh", background: "#E5E5E5", display: "flex" }}>
			<div className="bot-forth-step" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
				<div style={Parentdiv}>
					<div style={Childdiv}>
						<span style={progresstext} className='progress-bar-text'>{`Test Completed ${progress}%`}</span>
					</div>
				</div>
			</div>
			<div className='chatbox-timer' style={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
				<div className='chatbox-timer-wrapper'>
					<div className='chatbox-timer-box'>
						<Modal open={completedChatModal} onClose={onCloseCompletedChatModal} center styles={{ modal: { borderRadius: "50px", padding: "40px" } }}>
							<p className="modal-text">
								You're done! Click OK to leave the chat window
							</p>
							<div className="button-div">
								<Button
									className="modal-button"
									style={{
										background: '#479BD2',
										color: '#fff',
										fontWeight: 'bold',
										fontFamily: "Poppins",
										letterSpacing: "0.01em",
										borderRadius: '25px',
										padding: '5px 12px',
										fontSize: '16px',
									}}
									onClick={() => {
										onCloseCompletedChatModal()
									}} >
									<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Ok</span>
								</Button>
							</div>
						</Modal>
						<CountdownCircleTimer
							isPlaying={timerState}
							duration={totalChatTime * 60}
							colors={[
								['#42B6D6', 1]
							]}
							strokeWidth={useWindowDimensions().height / 200}
							size={0}
							initialRemainingTime={totalChatTime * 60 - startT}
							trailColor="#C4C4C4"
							onComplete={onOpenCompletedChatModal}
							style={{ margin: "auto" }}
						>
							{renderTime}
						</CountdownCircleTimer>
					</div>
				</div>
				<div style={{ display: "flex", margin: "auto 6.5vw", flexDirection: "column" }}>
					<Modal open={modal} onClose={onCloseModal} styles={{ modal: { padding: "40px", borderRadius: "50px" } }} center>
						<p className="modal-text">
							Are you sure you want to end the case?
						</p>
						<div className="button-div">
							<Button
								className="modal-button"
								style={{
									background: '#479BD2',
									color: '#fff',
									fontWeight: 'bold',
									fontFamily: "Poppins",
									letterSpacing: "0.01em",
									borderRadius: "25px",
									marginLeft: "10px",
									padding: '5px 12px',
									fontSize: '16px',
								}}
								onClick={onSubmit} >
								<span style={{ color: '#fff', fontSize: '16px', fontWeight: 'bold', textTransform: 'none' }}>Yes, submit!</span>
							</Button>
							<Button
								className="cancel-modal-button"
								style={{
									background: '#479BD2',
									color: '#fff',
									fontWeight: 'bold',
									fontFamily: "Poppins",
									letterSpacing: "0.01em",
									borderRadius: '25px',
									padding: '5px 12px',
									fontSize: '16px',
								}}
								onClick={() => {
									onCloseModal()
								}} >
								<span style={{ color: '#fff', fontSize: '16px', fontWeight: 'bold', textTransform: 'none' }}>No, back to test</span>
							</Button>
						</div>
					</Modal>
					<Button
						className='bot-eighth-step'
						style={{
							background: "#27BC36",
							color: '#fff',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							textTransform: 'none'
						}}
						onClick={onOpenModal}
					>
						<span style={{ color: '#fff', fontSize: '16px', fontWeight: 'bold' }}>Submit</span>
					</Button>
				</  div>
			</div>
			<Feedback showFeedback={showFeedback} setShowFeedBack={setShowFeedBack} />
		</div>
	)
}

export default ProgressBar;
