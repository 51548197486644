import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { fetchMyDetails as fetchMyDetailsRequest, updateMyDetails as updateMyDetailsRequest } from '../../actions/app';
import { getMyDetails } from '../../selectors/app';

import '../../styles/myDetails.scss';

const MyDetails = (props) => {
	const { myDetails, fetchMyDetails, setErrorAlertMsg, onErrorAlertOpen, updateMyDetails, setSuccessAlertMsg, onSuccessAlertOpen } = props;

	const history = useHistory();
	
	const [name, setName] = useState('');
	const [role, setRole] = useState('');

	useEffect(() => {
		if(Object.keys(myDetails).length === 0) {
			fetchMyDetails({ history });
		} else {
			setName(myDetails.name);
			setRole(myDetails.designation);
		}
	}, [myDetails]);

	const saveChanges = () => {
		if(name === '') {
			setErrorAlertMsg('Enter valid name');
			onErrorAlertOpen();
		} else {
			const userData = {
				name,
				designation: role
			};

			updateMyDetails({ userData, history });
			setSuccessAlertMsg('Changes saved successfully');
			onSuccessAlertOpen();
		}
	}

	return (
		<div className='my-details'>
			<div className='input-group'>
				<div className='label'>Name</div>
				<div>
					<input type='text' value={name} onChange={(e) => setName(e.target.value)} />
				</div>
			</div>
			<div className='input-group'>
				<div className='label'>Email ID</div>
				<div>
					<input type='email' disabled value={myDetails.email} />
				</div>
			</div>
			<div className='input-group'>
				<div className='label'>Company Name</div>
				<div>
					<input type='text' disabled value={myDetails.company} />
				</div>
			</div>
			<div className='input-group'>
				<div className='label'>Role</div>
				<div>
					<input type='text' value={role} onChange={(e) => setRole(e.target.value)} />
				</div>
			</div>
			<div className='input-group'>
				<div className='label'>Access Level</div>
				<div>
					<input type='text' disabled value={myDetails.role} />
				</div>
			</div>
			<div className='my-details-actions'>
				<div onClick={saveChanges}>Save changes</div>
			</div>
		</div>
	)
}

const mapStateToProps = createStructuredSelector({
	myDetails: getMyDetails()
});

const mapDispatchToProps = (dispatch) => ({
	fetchMyDetails: (data) => dispatch(fetchMyDetailsRequest(data)),
	updateMyDetails: (data) => dispatch(updateMyDetailsRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(MyDetails);
