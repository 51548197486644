export const checkUserPlanAccess = (planName, planDetails, featureName, featureCount) => {
	if(planName) {
		if(featureCount) {
			return planDetails.features[featureName] < featureCount;
		} else {
			return planDetails.features[featureName];
		}
	} else {
		return true;
	}
}
