export const FETCH_BOTS = 'FETCH_BOTS';
export const FETCH_COMPANY_BOTS = 'FETCH_COMPANY_BOTS';
export const SET_CONVO = 'SET_CONVO';
export const SET_COMPANY_BOTS = 'SET_COMPANY_BOTS';
export const ADD_USER = 'ADD_USER';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const SET_WORKFLOW_FUNNEL_DATA = 'SET_WORKFLOW_FUNNEL_DATA';
export const GET_CANDIDATES_BY_TYPE = 'GET_CANDIDATES_BY_TYPE';
export const SET_CANDIDATES = 'SET_CANDIDATES';
export const EDIT_CANDIDATE_NAME = 'EDIT_CANDIDATE_NAME';
export const DELETE_CANDIDATE = 'DELETE_CANDIDATE';
export const SET_SUCCESS_MSG = 'SET_SUCCESS_MSG';
export const RESET_SUCCESS_MSG = 'RESET_SUCCESS_MSG';
export const RERENDER = 'RERENDER';
export const RESET_RERENDER = 'RESET_RERENDER';
export const EDIT_DRIVE_NAME = 'EDIT_DRIVE_NAME';
export const USER_SIGN_IN = 'USER_SIGN_IN';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_ERROR_MSG = 'AUTH_ERROR_MSG';
export const AUTH_SUCCESS_MSG = 'AUTH_SUCCESS_MSG';
export const IS_AUTHENTICATED = 'IS_AUTHENTICATED';
export const SET_REPORT_MODEL = 'SET_REPORT_MODEL';
export const USER_LOGOUT = 'USER_LOGOUT';
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const SETTINGS_LOADING = 'SETTINGS_LOADING';
export const FREE_USER_SIGN_IN = 'FREE_APP/USER_SIGN_IN';
export const FREE_USER_SIGN_UP = 'FREE_APP/USER_SIGN_UP';
export const SET_USER_VERIFICATION_LOADING = 'FREE_APP/SET_USER_VERIFICATION_LOADING';
export const SET_SHOW_VERIFICATION_SUCCESS = 'FREE_APP/SET_SHOW_VERIFICATION_SUCCESS';
export const VERIFY_USER = 'FREE_APP/VERIFY_USER';
export const SEND_FORGOT_PASSWORD_LINK = 'FREE_APP/SEND_FORGOT_PASSWORD_LINK';
export const RESET_PASSWORD = 'FREE_APP/RESET_PASSWORD';
export const SET_PASSWORD = 'FREE_APP/SET_PASSWORD';
export const SUBMIT_ASSESSMENT = 'FREE_APP/SUBMIT_ASSESSMENT';
export const FETCH_ASSESSMENT_HISTORY = 'FREE_APP/FETCH_ASSESSMENT_HISTORY';
export const SET_ASSESSMENT_HISTORY = 'FREE_APP/SET_ASSESSMENT_HISTORY';
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';
export const SET_DRIVE_DETAILS = 'SET_DRIVE_DETAILS';
export const GET_DRIVE_DETAILS = 'GET_DRIVE_DETAILS';
export const ADD_COMPANY = 'ADD_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const GET_COMPANIES = 'GET_COMPANIES';
export const SET_COMPANIES = 'SET_COMPANIES';
export const ADD_BOTS = 'ADD_BOTS';
export const DELETE_BOTS = 'DELETE_BOTS';
export const GET_SUPERUSER = 'GET_SUPERUSER';
export const SET_SUPERUSER = 'SET_SUPERUSER';
export const ADD_SUPERUSER = 'ADD_SUPERUSER';
export const DELETE_SUPERUSER = 'DELETE_SUPERUSER';
export const GET_FEEDBACK = 'GET_FEEDBACK';
export const SET_FEEDBACK = 'SET_FEEDBACK';
export const FETCH_MY_DETAILS = 'FETCH_MY_DETAILS';
export const SET_MY_DETAILS = 'SET_MY_DETAILS';
export const UPDATE_MY_DETAILS = 'UPDATE_MY_DETAILS';
export const CHANGE_MY_PASSWORD = 'CHANGE_MY_PASSWORD';
export const ADD_PRE_SCREENING_BOT = 'ADD_PRE_SCREENING_BOT';
export const EDIT_PRE_SCREENING_BOT = 'EDIT_PRE_SCREENING_BOT';
export const FETCH_PRE_SCREENING_BOTS = 'FETCH_PRE_SCREENING_BOTS';
export const DELETE_PRE_SCREENING_BOT = 'DELETE_PRE_SCREENING_BOT';
export const SET_PRE_SCREENING_BOTS = 'SET_PRE_SCREENING_BOTS';
export const FETCH_PRE_SCREENING_CHAT = 'FETCH_PRE_SCREENING_CHAT';
export const SET_PRE_SCREENING_CHAT = 'SET_PRE_SCREENING_CHAT';
export const SET_CURRENT_BOT_QUESTION = 'SET_CURRENT_BOT_QUESTION';
export const VERIFY_LINK_TOKEN = 'VERIFY_LINK_TOKEN';
export const FETCH_SAAS_PLAN_LIST = 'FETCH_SAAS_PLAN_LIST';
export const SET_SAAS_PLAN_LIST = 'SET_SAAS_PLAN_LIST';
export const FETCH_SAAS_PLAN_DETAILS = 'FETCH_SAAS_PLAN_DETAILS';
export const SET_SAAS_PLAN_DETAILS = 'SET_SAAS_PLAN_DETAILS';
export const UPDATE_SAAS_PLAN_DETAILS = 'UPDATE_SAAS_PLAN_DETAILS';
export const UPDATE_ASSESSMENT_BOT_LOGO = 'UPDATE_ASSESSMENT_BOT_LOGO';
export const UPDATE_DASHBOARD_LOGO = 'UPDATE_DASHBOARD_LOGO';
export const SET_DASHBOARD_LOGO = 'SET_DASHBOARD_LOGO';
export const CREATE_WORKFLOW_JOB_ROLE = 'CREATE_WORKFLOW_JOB_ROLE';
export const FETCH_WORKFLOW_DETAILS = 'FETCH_WORKFLOW_DETAILS';
export const SET_JOB_ROLE_DETAILS = 'SET_JOB_ROLE_DETAILS';
export const SET_CREATE_JOB_LOADING = 'SET_CREATE_JOB_LOADING';
export const SEND_PRE_SCREENING_TEST = 'SEND_PRE_SCREENING_TEST';
export const SET_TRIAL_USERS_LIST = 'SET_TRIAL_USERS_LIST';
export const FETCH_TRIAL_USERS_LIST = 'FETCH_TRIAL_USERS_LIST';
export const SEND_UPDATE_ALL = 'SEND_UPDATE_ALL';
export const UPDATE_COMPANY_FEATURES = 'UPDATE_COMPANY_FEATURES';
export const FETCH_COMPANY_DETAILS = 'FETCH_COMPANY_DETAILS';
export const SET_COMPANY_DETAILS = 'SET_COMPANY_DETAILS';
export const UPDATE_COMPANY_DETAILS = 'UPDATE_COMPANY_DETAILS';
export const UPDATE_BOT_DETAILS = 'UPDATE_BOT_DETAILS';
